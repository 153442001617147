@charset "utf-8";
html, body, #root {
  height:100%;
}


/* reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button {
  margin:0;
  padding:0;
}


/* shards-dashboard custom */
.btn-primary {
  border-color: #5664D2;
  background-color: #5664D2;
}
.btn-warning {
  border:none;
}

.btn-light {
  border-color: #e1e2e5;
  background-color: #e1e2e5;
}

.form-control {
  border-color:#eeeeee;
}

.input-group > .form-control:not(:first-child.border-radius) {
  border-radius:0.25rem;
}


fieldset, img {
  border:0 none;
  vertical-align:top;
}
dl,ul,ol,li {
  list-style:none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,q:before, q:after {
  content:'';
  content:none;
}
input,select,textarea,button {
  vertical-align:middle;
}
button {
  border:0 none;
  background-color:transparent;
  cursor:pointer;
}
body {
  background:#fff;
}
body, th, td, input, select, textarea, button {
  font-size:12px;
  line-height:1.5;
  font-family: 'Lato','Noto Sans KR', Malgun Gothic,"맑은 고딕",AppleGothic,Dotum,"돋움",sans-serif;
  color:#000;
}

a {
  color:#333;
  text-decoration:none
}
a:active, a:hover {
  text-decoration:none;
}
address,caption,cite,code,dfn,em,var {
  font-style:normal;
  font-weight:normal
}

html, body, div, ul, ol, dl, li, dt, dd, p,
h1, h2, h3, h4, h5, h6, table, thead, tbody, tr, th, td, caption, address,
canvas, details, figure, figcaption, footer, header, menu, nav, section, article, aside,
button, select, textarea, input, fieldset, legend, label,
a, span, strong, em, address, dfn, small, i, b, ::before, ::after {
  margin:0;
  padding:0;
  border:0;
  outline:none;
  letter-spacing:-0.01em;
  font-family: 'Lato','Noto Sans KR', Malgun Gothic,"맑은 고딕",AppleGothic,Dotum,"돋움",sans-serif;
}
/* 레이어팝업 오픈상태 */
body.layerOpen {
  overflow:hidden;
}
[hidden], hr {
  display: none;
}
button {
  cursor:pointer;
}
a, button {
  text-decoration:none;
  -webkit-transition:all 0.3s ease;
  -moz-transition:all 0.3s ease;
  -o-transition:all 0.3s ease;
  transition:all 0.3s ease;
}
button {
  overflow:hidden;
  background-color:transparent;
  cursor:pointer;
}
button::-moz-focus-inner {
  border:0;
  padding:0;
}

/* 공통 */
/* 숨김목록 */
.hidden-cont{
  /*display:none;*/
}
/* section-box */
.section-box{
  position:relative;
  padding:20px;
  background:#ffffff;
  -moz-box-shadow:0 0 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow:0 0 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow:0 0 20px 0 rgba(0, 0, 0, 0.1);
}


/* table : input checkbox  */
input[type="checkbox"]{
  position:relative;
  display:inline-block;
  background:url("../images/img/table_checkbox.svg")no-repeat 0 0;
  width:20px;
  height:20px;
  -webkit-appearance:none;
  cursor:pointer;
  vertical-align:middle;
}
input[type="checkbox"]:checked{
  background:url("../images/img/table_checkbox_on.svg")no-repeat 0 0;
}

/* select */
select{
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background:url("../images/img/icon_down_arrow.svg")no-repeat right 15px center;
  background-size:16px;
}

/* input : radio hidden */
input[type="radio"]{
  width:0;
  visibility:hidden;
}

/* input : switch */
.btn-lm, .btn-group-lm > .btn {
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-switch{
  position:relative;
  display:inline-block;
  width:40px;
  height:20px;
  vertical-align:middle;
}
.btn-switch input[type="checkbox"]{
  opacity:0;
  width:0;
  height:0;
}
.btn-switch .slider{
  position:absolute;
  cursor:pointer;
  top:0;
  left:0;
  right:0;
  bottom:0;
  padding:0;
  background-color:#ffffff;
  -webkit-transition:0.4s;
  transition:0.4s;
  border-radius:20px;
  border:1px solid #CED4DA;
}
.btn-switch .slider:before{
  position:absolute;
  content:"";
  height:14px;
  width:14px;
  left:3px;
  top:50%;
  margin-top:-7px;
  background-color:#CED4DA;
  -webkit-transition:0.4s;
  transition:0.4s;
  border-radius:50%;
}
input:checked + .slider{
  background-color:#5664D2;
  border:none;
}
input:checked + .slider:before{
  -webkit-transform:translateX(20px);
  -ms-transform:translateX(20px);
  transform:translateX(20px);
  background-color:#ffffff;
  bottom:3px;
}

.section-tit{
  display:block;
  padding:0 0 20px 0;
  text-align:left;
}
.section-tit h3{
  font-size:16px;
  font-weight:700;
  color:#000000;
}
.table-tit{
  padding:0 0 14px 0;
}
.table-tit > h4{
  display:block;
  font-size:14px;
  font-weight:500;
  color:#000000;
}

.addon-text-notice .input-group-text {
  border:none;
  color:#000000;
  font-weight:700;
}

/* table 기본값 */
.left{
  text-align:left!important;
}
table{
  width:100%;
  border:1px solid #EFF2F7;
  box-sizing:border-box;
  text-align:left;
  border-collapse:collapse;
}
table thead{
  width:100%;
  height:46px;
  background:#EFF2F7;
  text-align:center;
}
table thead tr{
  width:100%;
}
table thead tr th{
  font-size:13px;
  font-weight:500;
  color:#000000;
  padding:0 21px;
  border:none;
  line-height:1;
}
table tbody tr{
  width:100%;
}
table tbody tr th{
  font-size:13px;
  font-weight:400;
  color:#000000;
  border:1px solid #EAEAEA;
  padding:0 0 0 20px;
  box-sizing:border-box;
  line-height:1;
}
table tbody tr td{
  font-size:13px;
  font-weight:400;
  color:#000000;
  padding:10px;
  height:36px;
  border:1px solid #EAEAEA;
  text-align: left;
}
table tr td input[type="text"],
table tr td input[type="password"],
table tr td input[type="number"]{
  border:1px solid #CED4DA;
  box-sizing:border-box;
  padding:0 10px;
  font-size:12px;
  font-weight:400;
  color:#000000;
}

table tr td .guide-text{
  display:block;
  font-size:12px;
  font-weight:400;
  color:#74788D;
  line-height:1;
  padding:10px 0 0 0;
}
/* table : button */
.btn-table{
  display:inline-block;
  height:28px;
  padding:0 8px;
  background:#343A40;
  border-radius:5px;
  text-align:center;
  font-size:13px;
  font-weight:400;
  color:#ffffff;
  line-height:28px;
  margin:0 0 0 10px;
  vertical-align:middle;
}
/* button : 페이지 전체 저장 */
.btn-save{
  text-align:center;
  padding:20px 0 0 0;
}
.btn-save .save-button{
  min-width:100px;
  height:38px;
  padding:0 16px;
  border-radius:5px;
  font-size:14px;
  font-weight:700;
  color:#ffffff;
  background:#5664D2;
  box-sizing:border-box;
  cursor:pointer;
}
.btn-save .save-button.disabled {
  background: #707080;
  cursor: crosshair;
}

/* input : number : 증가,감소 버튼 제거 */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin:0;
}

/* icon */
/* 툴팁 아이콘 */
.tooltip-icon{
  display:inline-block;
  width:14px;
  height:14px;
  vertical-align:middle;
}
.tooltip-icon .nd-icon{
  width:100%;
  height:100%;
  color:#5664D2;
}

/* 옵션추가 버튼 */
.btn-add-option{
  display:inline-block;
  width:28px;
  height:28px;
  background:#343A40;
  border-radius:5px;
  text-align:center;
  vertical-align:middle;
  line-height:28px;
}
.btn-add-option .nd-icon{
  width:16px;
  height:16px;
  color:#ffffff;
}

/* 옵션제거 버튼 */
.btn-del-option{
  display:inline-block;
  width:28px;
  height:28px;
  background:#74788D;
  border-radius:5px;
  text-align:center;
  vertical-align:middle;
  line-height:1;
}
.btn-del-option .nd-icon{
  width:16px;
  height:16px;
  color:#ffffff;
}

/* textarea 크기 고정 */
textarea{
  resize:none;
}

/* text color */
.text-red{
  color:#FF3D60;
}

/* pagination */
.pagination{
  display:block;
  padding:15px 0 0 0;
}
.pagination ul{

  text-align:center;
}
.pagination ul li {
  position:relative;
  display:inline-block;
  width:34px;
  height:34px;
  border:1px solid #CED4DA;
  box-sizing:border-box;
  text-align:center;
  vertical-align:middle;
  line-height:34px;
  font-size:13px;
  font-weight:400;
  color:#74788D;
  margin:0 3px;
}
.pagination ul li.on{
  background:#5664D2;
  color:#ffffff;
  border:none;
}
/*.pagination ul li.on a{*/
/*  color:#ffffff;*/
/*}*/
.pagination ul li.btn-page-first{
  border-top-left-radius:5px;
  border-bottom-left-radius:5px;
  border-left:1px solid #CED4DA;
}
.pagination ul li.btn-page-last{
  border-top-right-radius:5px;
  border-bottom-right-radius:5px;
}
.pagination ul li.btn-more {
  width: auto;
  border:none;
}
.pagination ul li a{
  position:absolute;
  display:block;
  width:100%;
  height:100%;
}
.pagination ul li.on a {
  color:#ffffff;
}
.pagination ul li a .nd-icon{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  width:16px;
  height:16px;
  color:#74788D;
}


.wrap{
  position:relative;
  width:100%;
  height:100%;
}

/* layout */
.contents{
  position:absolute;
  left:0;
  top:0;
  width:calc(100% - 0px);
  padding-left:105px;
  min-width:1200px;
  height:100%;
  /*min-height:1290px;*/
  background:#F4F5F8;
  margin:0 0 0 auto;
  overflow-y:scroll;
}
.contents:after{
  content:"";
  display:block;
  clear:both;
}
.contents .main-content {
  position:relative;
  width:100%;
  padding:30px 30px 50px;
  box-sizing:border-box;
}

.vertical-middle {
  vertical-align:middle;
}

.mtb-10 {
  margin-bottom: 10px;
}


/*.react-calendar */
.react-calendar {
  position: absolute;
  z-index: 9;
}
.react-calendar.endDate {
  margin-left: 244px;
}
.react-datetime-picker__wrapper {
  border-color: #5A6169;
  border-radius:3px;
}


.overlay {
  position: fixed; /* 화면에 고정 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  z-index: 999;
}
.overlay .blocks-wrapper {
  position: absolute;
  top:50%;
  left:50%;
  margin-top:-40px;
  margin-left:-40px;
}

/* 버튼 */
.btn-remove {
  cursor: pointer;
  background-color: #1d1c1c;
  border-radius: 50%;
  position: absolute;
  top:-10px;
  right:-10px;
  font-size:16px !important;
  font-weight: bold;
  color: #ffffff;
  padding:3px;
}

.warning-font {
  font-size:12px;
  color: red;
}

.btn-modal-close {
  position: absolute;
  right:17px;
  padding:0.1rem 0.1rem !important;
}

.force-hide {
  display: none!important;
}

table.ss-table {
  width: 600px;
}
table.s-table {
  width: 1000px;
}



/* shepherd-content */


.shepherd-arrow::before {
  display: none;
}

.shepherd-element .shepherd-arrow:after {
  content: url('../assets/arrow.svg');
  display: inline-block;
}

.shepherd-element[data-popper-placement^='top'] .shepherd-arrow,
.shepherd-element.shepherd-pinned-top .shepherd-arrow {
  bottom: 1px;
}

.shepherd-element[data-popper-placement^='top'] .shepherd-arrow:after,
.shepherd-element.shepherd-pinned-top .shepherd-arrow:after {
  transform: rotate(270deg);
}

.shepherd-element[data-popper-placement^='bottom'] .shepherd-arrow {
  top: -35px;
}

.shepherd-element[data-popper-placement^='bottom'] .shepherd-arrow:after {
  transform: rotate(90deg);
}

.shepherd-element[data-popper-placement^='left'] .shepherd-arrow,
.shepherd-element.shepherd-pinned-left .shepherd-arrow {
  right: -35px;
}

.shepherd-element[data-popper-placement^='left'] .shepherd-arrow:after,
.shepherd-element.shepherd-pinned-left .shepherd-arrow:after {
  transform: rotate(180deg);
}

.shepherd-element[data-popper-placement^='right'] .shepherd-arrow,
.shepherd-element.shepherd-pinned-right .shepherd-arrow {
  left: -35px;
}

.shepherd-element {
  box-shadow: none;
  background-color: transparent;
}
.shepherd-element .shepherd-content {
  background-color:#ffffff;
  border:solid 4px #16202D;
  margin:10px;
}
.shepherd-element .shepherd-content .shepherd-text {
  font-size: 0.9rem;
  line-height: 1.5em;
}

select {
  width:150px;
  height:38px;
  border:1px solid #CED4DA;
  box-sizing:border-box;
  padding:0 20px 0 7px;
  background-position:right 7px center;
}
.required-indicator {
  color: red;
  font-weight: bold;
}