.terms-privacy {
  height: 350px;
  padding: 20px 10px;
  overflow: auto;
  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 17px;
    margin-bottom: 7px;
  }
}
.serviceAgreeCheck {
  margin: 20px 20px 0 0;
    label {
        font-size: 14px;
        margin-left: 5px;
    }
}